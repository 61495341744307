import React, { useState } from 'react';
import { Drawer, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from 'formik';
import CustomQuestionForm from './CustomQuestionForm';
import ConfirmationModal from '../../../Common/ConfirmationModal/ConfirmationModal';
import { text } from '../../../../Consts/customQuestionInputValidationType';
import { getValidationSchema } from './CustomQuestionFormValidationSchema';
import DrawerActions from '../../../Common/Drawer/DrawerActions';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('sm')]: {
      width: 567,
    },
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const CustomQuestionDrawer = ({
  customQuestionDrawerData,
  questionDrawerCallback,
  handleClose,
}) => {
  const classes = useStyles();
  const [cancelModal, setCancelModal] = useState(false);
  const openModal = () => setCancelModal(true);
  return (
    <Formik
      initialValues={{
        id: customQuestionDrawerData.question.id ?? null,
        label: customQuestionDrawerData.question.label ?? '',
        type: customQuestionDrawerData.question.type ?? '',
        validation: customQuestionDrawerData.question.validation ?? text,
        values: customQuestionDrawerData.question.values?.map(
          (v) => v.value
        ) ?? ['', ''],
        file: customQuestionDrawerData.question.fileName
          ? { name: customQuestionDrawerData.question.fileName }
          : null,
        information: customQuestionDrawerData.question.information,
        filesToRemove: customQuestionDrawerData.question.filesToRemove ?? [],
        subQuestions:
          (() => {
            return customQuestionDrawerData.question.subQuestions?.map((s) => {
              const updatedSubQuestion = {
                ...s,
                file: s.fileName ? { name: s.fileName } : null,
                filesToRemove: s.filesToRemove ?? [],
                values: s.values.map((v) => v.value) ?? ['', ''],
              };

              return updatedSubQuestion;
            });
          })() ?? [],
      }}
      validationSchema={getValidationSchema()}
      onSubmit={async (values) => {
        await questionDrawerCallback(values);
      }}
    >
      {(customQuestion) => (
        <Drawer
          open={customQuestionDrawerData.open}
          anchor="right"
          onClose={customQuestion.dirty ? openModal : handleClose}
        >
          <form>
            <Grid
              container
              spacing={0}
              justifyContent="center"
              className={classes.root}
            >
              <Grid xs={12} sm={11} container spacing={2}>
                <Grid xs={12}>
                  <Typography variant="h3">Add Custom Question</Typography>
                </Grid>
                <Grid xs={12}>
                  <CustomQuestionForm {...customQuestion} />
                </Grid>
                <DrawerActions
                  onClose={customQuestion.dirty ? openModal : handleClose}
                  handleSubmit={customQuestion.handleSubmit}
                  isSubmitting={customQuestion.isSubmitting}
                  submittingMessage={'Saving'}
                  submitText={'Save'}
                  cancelText={
                    !customQuestionDrawerData.question.id ? 'Cancel' : 'Close'
                  }
                />
              </Grid>
            </Grid>
            {cancelModal && (
              <ConfirmationModal
                cancel={handleClose}
                confirm={() => {
                  customQuestion.handleSubmit();
                  setCancelModal(false);
                }}
                open={cancelModal}
                title="You haven't saved your work!"
                content="Closing this will discard any unsaved actions."
                cancelText="Close and discard"
                confirmText="Save"
              />
            )}
          </form>
        </Drawer>
      )}
    </Formik>
  );
};

export default CustomQuestionDrawer;
