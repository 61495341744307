import React from 'react';
import {
  ListItemIcon,
  FormGroup,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  IconButton,
  List,
  ListItem,
  Link,
  Button,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { FieldArray, Field } from 'formik';
import FormikTextField from '../../../Common/Formik/FormikTextField';
import * as validationType from '../../../../Consts/customQuestionInputValidationType';

const CustomQuestionOptions = ({ options, error, namePrefix }) => (
  <FieldArray
    name={`${namePrefix}values`}
    render={(arrayHelpers) => (
      <List>
        {options.map((_option, index) => (
          <ListItem disableGutters key={index}>
            <FormikTextField
              variant="outlined"
              placeholder={`Answer option ${index + 1}`}
              fullWidth
              name={`${namePrefix}values.${index}`}
              explicitError={error && error.length && error[index]}
            />
            {options.length > 2 && (
              <ListItemIcon>
                <IconButton
                  onClick={() => arrayHelpers.remove(index)}
                  size="large"
                >
                  <Close />
                </IconButton>
              </ListItemIcon>
            )}
          </ListItem>
        ))}
        <ListItem>
          <Button
            component={Link}
            onClick={() => arrayHelpers.push('')}
            disabled={!Boolean(options[options.length - 1])}
          >
            + Add option
          </Button>
        </ListItem>
      </List>
    )}
  />
);

const TextValidationOptions = ({ namePrefix, value }) => (
  <FormGroup component={'fieldset'}>
    <FormLabel component={'label'}>Validation</FormLabel>
    <RadioGroup name={`${namePrefix}validation`} value={value}>
      <FormControlLabel
        value={validationType.text}
        control={<Field as={Radio} />}
        label="Free text"
      />
      <FormControlLabel
        value={validationType.number}
        control={<Field as={Radio} />}
        label="Number"
      />
      <FormControlLabel
        value={validationType.email}
        control={<Field as={Radio} />}
        label="Email address"
      />
      <FormControlLabel
        value={validationType.time}
        control={<Field as={Radio} />}
        label="Time (HH:MM)"
      />
    </RadioGroup>
  </FormGroup>
);

export { CustomQuestionOptions, TextValidationOptions };
