import React, { useContext, useState } from 'react';
import { Paper, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import { Mail, Add } from '@mui/icons-material';
import { useParams } from 'react-router';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import EditIcon from '@mui/icons-material/Edit';
import { countries } from '../../../Consts/countries';
import DisplayField from '../../Common/DisplayField/DisplayField';
import { file_download } from '../../../Consts/inputType';
import Timeline from './Timeline';
import LinkLevelUp from '../../Common/LinkLevelUp/LinkLevelUp';
import { Context } from '../../../Stores/EventInfoStore';
import OrderStatusPill from '../OrderStatusPill';
import ResendEmailDrawer from './OrderActions/ResendEmailDrawer';
import CancelOrderDrawer from './OrderActions/CancelOrderDrawer';
import RefundOrderDrawer from './OrderActions/RefundOrderDrawer';
import AddOrderNoteDrawer from './OrderActions/AddOrderNoteDrawer';
import ManageOrderDrawer from './OrderActions/ManageOrderDrawer';
import EditParticipantDrawer from './OrderActions/EditParticipantDrawer';
import * as resendDetailsOptions from './OrderActions/resendDetailsOptions';
import WrappedDivider from '../../Common/WrappedDivider/WrappedDivider';
import ConfirmationModal from '../../Common/ConfirmationModal/ConfirmationModal';
import OrderActionsMenu from './OrderActionsMenu';
import useQueryParams from '../../../Hooks/useQueryParams';
import { useWebhooks } from '../../../Hooks/useWebhooks';
import { useApi, methods } from '../../../Hooks/useApi';
import * as ticketStatuses from '../../../Consts/ticketStatus';
import * as orderStatus from '../../../Consts/orderStatus';
import GenericModal from '../../Common/GenericModal/GenericModal';
import BlueLink from '../../Common/BlueLink/BlueLink';
import { Emails } from '../../../Utils/urls';

const getResendWebhookUrl = (eventId, editionId, orderId) =>
  `/ro/events/${eventId}/races/${editionId}/orders/${orderId}/sendwebhook`;

const getCanRefundUrl = (eventId, editionId, orderId) =>
  `/order/${eventId}/${editionId}/${orderId}/canrefund`;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 1),
  },
  form: {
    padding: theme.spacing(1, 4, 4, 4),
    fontSize: 14,
  },
  normalWeigth: {
    fontWeight: 'normal',
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
}));

const topFieldParams = {
  boldLabel: true,
  containerSize: 12,
  labelSize: 2,
  valueSize: 4,
};

const getFullCountryName = (countryCode) => {
  return countries.hasOwnProperty(countryCode)
    ? countries[countryCode].label
    : countryCode;
};

const getCustomAnswer = (question, participant) => {
  let answer = participant.extraInformationObject.find(
    (answer) => answer.questionId === question.id
  );
  if (typeof answer === 'undefined') {
    answer = participant.extraInformationObject.find(
      (answer) => answer.label === question.label
    );
  }

  if (Array.isArray(answer?.value)) {
    return answer.value.map((v) => v.value).join(', ');
  }

  if (typeof answer?.value === 'object') {
    return Object.values(answer.value).join(', ');
  }

  if (typeof answer?.value === 'number') {
    const matchedValue = question.customQuestionValues.find(
      (customQuestionValue) => customQuestionValue.id === answer?.value
    );
    return matchedValue
      ? matchedValue.value
      : answer?.value
      ? answer.value
      : '';
  }
  return answer?.value ? answer.value : '';
};

const requiredQuestions = (participant) => {
  return {
    Name: participant.fullName,
    Email: participant.email,
    Gender: participant.gender,
    'Date of Birth': participant.birthDate.date
      ? format(new Date(participant.birthDate.date), 'dd. MMM. yyyy')
      : '',
    Nationality: getFullCountryName(participant.nationality),
  };
};

const OrderDetails = ({ orders }) => {
  const classes = useStyles();
  const { orderId } = useParams();
  const [eventInfo] = useContext(Context);
  const { executeApiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const { event } = eventInfo;
  const { eventId, editionId } = event;
  const { webhooks } = useWebhooks(eventId);

  const fromTab = parseInt(useQueryParams().get('tab'));
  const [drawerState, toggleDrawerState] = useState({
    open: false,
    resendContent: null,
  });
  const [cancelOrderDrawer, toggleCancelOrderDrawer] = useState(false);
  const [addNoteDrawer, toggleAddNoteDrawer] = useState(false);
  const [refundOrderDrawer, toggleRefundOrderDrawer] = useState(false);
  const [manageOrderDrawer, toggleManageOrderDrawer] = useState(false);
  const [editParicipantDrawer, toggleEditParticipantDrawer] = useState(false);
  const [editParticpantModal, toggleEditParticipantModal] = useState(false);
  const [resendWebhookModal, toggleResendWebhookModal] = useState(false);
  const [manageTicket, setManageTicket] = useState();
  const initOrder = orders?.find((o) => o.orderId === orderId);
  const [order, setOrder] = useState(initOrder);
  const [participant, setParticipant] = useState({});
  const [canRefundOptions, setCanRefundOptions] = useState({
    canRefund: false,
    message: '',
  });
  const [oldOrderModal, toggleOldOrderModal] = useState(false);
  const tickets = order
    ? order.participants.flatMap((p) =>
        p.orderLines.filter((l) => l.productType === 'race')
      )
    : [];
  const addonDetailKeyToExclude = 'id';

  React.useEffect(() => {
    setOrder(initOrder);
  }, [orders]);

  React.useEffect(() => {
    checkRefundOptions();
  }, []);

  const checkRefundOptions = async () => {
    await canRefund().then((response) => {
      if (response) {
        setCanRefundOptions((prev) => ({
          ...prev,
          canRefund: response.canRefund,
        }));
        if (response.message) {
          setCanRefundOptions((prev) => ({
            ...prev,
            message: response.message,
          }));
        }
      }
    });
  };

  const openEmailDrawer = (type, participantId, email) =>
    toggleDrawerState({
      open: true,
      prefilledEmail: email ?? order.orderer.email,
      content: resendDetailsOptions.getResendDrawerFields(
        type,
        orderId,
        event.eventId,
        event.editionId,
        participantId
      ),
    });
  const closeDrawer = () => toggleDrawerState({ open: false, content: null });

  const customQuestions = (participant) => {
    let participantQuestionIds = participant.extraInformationObject.map(
      (a) => a.questionId
    );
    let allQuestions = {};
    eventInfo.customQuestions
      .filter((question) => question.type !== file_download)
      .forEach((question) => {
        if (
          question.appliedTickets?.includes(
            order.teamTicket
              ? order.orderer.ticket.productId
              : participant.ticket.productId
          ) ||
          question.appliedTickets?.length === 0
        ) {
          allQuestions[`${question.id}`] = {
            label: question.label,
            answer: getCustomAnswer(question, participant),
          };
          if (question.subQuestions.length > 0) {
            question.subQuestions
              .filter(
                (sub) =>
                  sub.type !== file_download &&
                  participantQuestionIds.includes(sub.id)
              )
              .forEach((subQuestion) => {
                allQuestions[`${subQuestion.id}`] = {
                  label: subQuestion.label,
                  answer: getCustomAnswer(subQuestion, participant),
                };
              });
          }
        }
      });

    return allQuestions;
  };

  const getIsInvitation = () =>
    order.manuallyAdded && order.totalValueForOrganizer === 0;

  const isRefundable = () => {
    return (
      order.isRefundable &&
      !eventInfo.blockRefunds &&
      canRefundOptions.canRefund
    );
  };

  const canResendEmails = () =>
    ![
      orderStatus.refunded,
      orderStatus.refundPending,
      orderStatus.refundPendingCancellationPolicy,
      orderStatus.refundPendingOrganiserCancelled,
      orderStatus.cancelled,
    ].some((x) => order.status === x);

  const canCancel = () =>
    ![
      orderStatus.refunded,
      orderStatus.awaitingPayment,
      orderStatus.refundPendingOrganiserCancelled,
      orderStatus.refundPendingCancellationPolicy,
      orderStatus.cancelled,
    ].some((x) => order.status === x);

  const canResendWebhook = () =>
    webhooks?.some((w) => w.status === 'enabled') &&
    (order.status === orderStatus.successful ||
      order.status === orderStatus.partiallyRefunded);

  const resendWebhook = async () => {
    try {
      await executeApiCall(
        getResendWebhookUrl(eventId, editionId, orderId),
        methods.get
      );
      toggleResendWebhookModal(false);
      enqueueSnackbar('Webhook resend triggered successfully', {
        variant: 'success',
      });
    } catch {
      enqueueSnackbar('Webhook resend unsuccessful', { variant: 'error' });
    }
  };

  const canRefund = async () => {
    try {
      const result = await executeApiCall(
        getCanRefundUrl(eventId, editionId, orderId),
        methods.get
      );
      return result;
    } catch {}
  };

  const formatOldOrderMessage = (message, email) => {
    const emailLink = <BlueLink text={email} href={`mailto:${email}`} />;
    if (message) {
      const messageParts = message.split('{0}');
      if (messageParts.length === 2) {
        return (
          <>
            {messageParts[0]}
            {emailLink}
            {messageParts[1]}
          </>
        );
      }
    }
    return '';
  };

  const hasProductDiscountCouponApplied = () => {
    const productDiscountCodes = tickets.map((t) => t.productDiscountCode);
    return productDiscountCodes.length > 0 && productDiscountCodes[0];
  };

  const getCouponText = () => {
    const productDiscounts = tickets.map((t) => {
      return {
        productDiscountCode: t.productDiscountCode,
        percentageDiscount: t.discount,
      };
    });
    if (order?.coupon) {
      return (
        <DisplayField label={'Applied coupon'} {...topFieldParams}>
          {order.coupon?.code}{' '}
          {order.coupon?.percentage
            ? `(${order.coupon?.value}% discount)`
            : `(${
                order.coupon?.value
              } ${order.currency.toUpperCase()} discount)`}
        </DisplayField>
      );
    }
    if (productDiscounts[0]) {
      return (
        <DisplayField label={'Applied coupon'} {...topFieldParams}>
          {productDiscounts[0].productDiscountCode}{' '}
          {`(${productDiscounts[0].percentageDiscount}% discount)`}
        </DisplayField>
      );
    }
  };

  return (
    <>
      <LinkLevelUp
        label={`Back to all ${
          fromTab === 0 || !fromTab ? 'participants' : 'orders'
        }`}
        query={fromTab ? `tab=${fromTab}` : null}
      />
      <Paper>
        <div className={classes.form}>
          {(!eventInfo || !order) && (
            <Typography variant="body1" color="initial">
              Loading order...
            </Typography>
          )}

          {order && eventInfo && (
            <>
              <div className={classes.root}>
                <Grid container spacing={0}>
                  <Grid xs={10}>
                    <Typography variant="h3" gutterBottom>
                      Order reference: {order.orderReference}
                    </Typography>
                  </Grid>
                  <Grid xs={2}>
                    <OrderActionsMenu
                      resendOrderReceipt={() =>
                        openEmailDrawer(resendDetailsOptions.resendOrderReceipt)
                      }
                      refundOrder={
                        isRefundable()
                          ? () => toggleRefundOrderDrawer(true)
                          : order.isRefundable && !eventInfo.blockRefunds
                          ? () => toggleOldOrderModal(true)
                          : null
                      }
                      cancelOrder={() => toggleCancelOrderDrawer(true)}
                      resendWebhook={() => toggleResendWebhookModal(true)}
                      canCancel={canCancel()}
                      canRefund={isRefundable()}
                      canResend={canResendEmails()}
                      canResendWebhook={canResendWebhook()}
                    />
                  </Grid>
                </Grid>
                <DisplayField
                  label={'Order date'}
                  {...topFieldParams}
                  type="dateTime"
                >
                  {order.orderDate}
                </DisplayField>
                <DisplayField
                  label={'Paid amount'}
                  {...topFieldParams}
                  type={getIsInvitation() ? '' : 'price'}
                  currency={order.currency}
                >
                  {getIsInvitation()
                    ? 'Free (added/invited by organiser)'
                    : order.totalValueForOrganizer}
                </DisplayField>
                {Boolean(order.refundedValueForOrganizer) && (
                  <DisplayField
                    {...topFieldParams}
                    label="Refunded amount"
                    type="price"
                    currency={order.currency}
                  >
                    {order.refundedValueForOrganizer}
                  </DisplayField>
                )}
                {(order?.coupon || hasProductDiscountCouponApplied()) &&
                  getCouponText()}
                <DisplayField label={'Order status'} {...topFieldParams}>
                  <OrderStatusPill
                    title={order.statusTitle}
                    status={order.status}
                    size="regular"
                  />
                </DisplayField>

                <WrappedDivider />

                {order.participants.map((p, i) => (
                  <Grid container spacing={3} key={p.id}>
                    <Grid container xs={12} alignItems="center" spacing={0}>
                      <Grid>
                        <Typography
                          className={classes.marginRight}
                          variant="h4"
                        >
                          Participant {i + 1}
                          {i === 0 ? (
                            <span className={classes.normalWeigth}>
                              {' '}
                              - Buyer
                            </span>
                          ) : (
                            ''
                          )}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Button
                          startIcon={<EditIcon />}
                          onClick={() => {
                            setParticipant(p);
                            toggleEditParticipantDrawer(true);
                          }}
                          color="secondary"
                        >
                          Edit
                        </Button>
                      </Grid>
                      {Object.entries(requiredQuestions(p)).map((value) => (
                        <DisplayField key={value[0]} label={value[0]}>
                          {value[1]}
                        </DisplayField>
                      ))}
                      <br />
                      {Object.entries(customQuestions(p)).map((value) => (
                        <DisplayField key={value[0]} label={value[1].label}>
                          {value[1].answer}
                        </DisplayField>
                      ))}
                    </Grid>
                    <Grid xs={12}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignContent="center"
                        alignItems="center"
                        spacing={0}
                      >
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          alignContent="center"
                        >
                          <Grid>
                            <Typography variant="h4">Ticket</Typography>
                          </Grid>
                          <Grid>
                            <Button
                              startIcon={<SyncAltIcon />}
                              onClick={() => {
                                setManageTicket(p);
                                toggleManageOrderDrawer(true);
                              }}
                              color="secondary"
                            >
                              Change
                            </Button>
                          </Grid>
                        </Grid>
                        <DisplayField label={'Ticket Name'}>
                          <Grid
                            direction="row"
                            alignItems="center"
                            container
                            spacing={2}
                          >
                            <Grid>
                              {!order.teamTicket
                                ? p.ticket.productName
                                : order.orderer.ticket.productName}
                            </Grid>
                            {!order.teamTicket && p.ticket.changedTicket && (
                              <Grid>
                                <OrderStatusPill
                                  status="changed"
                                  title="Changed"
                                  size="small"
                                />
                              </Grid>
                            )}
                            {order.ticketStatus?.status ===
                              ticketStatuses.invitationPending && (
                              <Grid>
                                <OrderStatusPill
                                  status={order.ticketStatus.status}
                                  title={order.ticketStatus.title}
                                  size="small"
                                />
                              </Grid>
                            )}
                          </Grid>
                        </DisplayField>
                        <DisplayField label={'Ticket ID'}>
                          {!order.teamTicket
                            ? p.ticket.productId
                            : order.orderer.ticket.productId}
                        </DisplayField>
                        <DisplayField
                          label={'Ticket Price'}
                          type="price"
                          currency={order.currency}
                        >
                          {!order.teamTicket
                            ? p.ticket.price
                            : order.orderer.ticket.price}
                        </DisplayField>
                      </Grid>
                    </Grid>
                    {p.orderLines.filter((x) => x.productType === 'extra')
                      .length > 0 && (
                      <Grid xs={12}>
                        <Typography
                          variant="h4"
                          gutterBottom
                          className={classes.tickets}
                        >
                          Add-on
                        </Typography>
                        <Grid container spacing={2}>
                          {p.orderLines
                            .filter((x) => x.productType === 'extra')
                            .map((addon) => (
                              <Grid key={`${p.id}-${addon.productId}`} xs={12}>
                                <DisplayField label="Add-on name">
                                  {addon.productName}
                                </DisplayField>
                                {Object.entries(addon.details).map((o, i) => {
                                  return (
                                    (i === 0 ||
                                      (i !== 0 &&
                                        o[0] !== addonDetailKeyToExclude)) && (
                                      <Grid
                                        container
                                        key={`${o[0]}-${i}`}
                                        spacing={0}
                                      >
                                        {i === 0 && (
                                          <DisplayField label="Option">
                                            {o[0]}: {o[1]}
                                          </DisplayField>
                                        )}
                                        {i !== 0 &&
                                          o[0] !== addonDetailKeyToExclude && (
                                            <DisplayField label="Additional question">
                                              {o[0]}: {o[1]}
                                            </DisplayField>
                                          )}
                                      </Grid>
                                    )
                                  );
                                })}
                                <DisplayField
                                  label="Add-on price"
                                  type="price"
                                  currency={order.currency}
                                >
                                  {addon.price}
                                </DisplayField>
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                    )}
                    <Grid xs={12}>
                      <Button
                        variant="outlined"
                        disabled={!canResendEmails()}
                        endIcon={<Mail />}
                        onClick={() =>
                          openEmailDrawer(
                            resendDetailsOptions.resendOrderConfirmation,
                            p.id,
                            p.email
                          )
                        }
                        color="secondary"
                      >
                        Resend confirmation email
                      </Button>
                    </Grid>
                    <WrappedDivider />
                  </Grid>
                ))}
                {order.donations?.amountInRaceCurrency &&
                  order.donations?.amountInRaceCurrency !== 0 && (
                    <Grid container spacing={3}>
                      <Grid xs={12}>
                        <Typography variant="h4" gutterBottom>
                          Donations
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <DisplayField label="To">
                          {order.donations?.title}
                        </DisplayField>
                        <DisplayField
                          label="Amount"
                          type="price"
                          currency={order.currency}
                        >
                          {order.donations?.amountInRaceCurrency}
                        </DisplayField>

                        <WrappedDivider paddingTop={4} />
                      </Grid>
                    </Grid>
                  )}
                <Grid container spacing={0}>
                  <Grid xs={12} md={10}>
                    <Typography variant="h4">Timeline</Typography>
                  </Grid>
                  <Grid xs={12} md={2}>
                    <Button
                      startIcon={<Add />}
                      onClick={() => toggleAddNoteDrawer(true)}
                      color="secondary"
                    >
                      Add order note
                    </Button>
                  </Grid>
                </Grid>
                <Timeline data={order.timeline}></Timeline>
              </div>
              {canResendEmails() && (
                <ResendEmailDrawer
                  drawerState={drawerState}
                  handleClose={closeDrawer}
                />
              )}
              {order.isRefundable && (
                <RefundOrderDrawer
                  open={refundOrderDrawer}
                  handleClose={() => toggleRefundOrderDrawer(false)}
                  order={order}
                />
              )}
              {oldOrderModal && (
                <GenericModal
                  open={oldOrderModal}
                  handleClose={() => toggleOldOrderModal(false)}
                  title="Refund is not available for this order"
                  content={formatOldOrderMessage(
                    canRefundOptions.message,
                    Emails.CustomerSuccess
                  )}
                />
              )}
              {manageOrderDrawer && (
                <ManageOrderDrawer
                  open={manageOrderDrawer}
                  eventId={eventId}
                  editionId={editionId}
                  handleClose={() => toggleManageOrderDrawer(false)}
                  order={order}
                  setOrder={setOrder}
                  ticket={manageTicket}
                  eventInfo={eventInfo}
                />
              )}
              {toggleEditParticipantDrawer && (
                <EditParticipantDrawer
                  open={editParicipantDrawer}
                  participant={participant}
                  setParticipant={setParticipant}
                  handleClose={(isSaving, handleReset) => {
                    if (isSaving) toggleEditParticipantDrawer(false);
                    else {
                      toggleEditParticipantModal(true);
                      handleReset();
                    }
                  }}
                  eventInfo={eventInfo}
                  order={order}
                  setOrder={setOrder}
                />
              )}
              {canCancel() && (
                <CancelOrderDrawer
                  open={cancelOrderDrawer}
                  handleClose={() => toggleCancelOrderDrawer(false)}
                />
              )}
              {canResendWebhook() && (
                <ConfirmationModal
                  open={resendWebhookModal}
                  cancel={() => toggleResendWebhookModal(false)}
                  confirm={resendWebhook}
                  title="Resend Webhook"
                  content={`This will resend the order.successful webhook to the endpoint(s) you have specified in the integrations section. 
                  The initial webhook is sent 24h after the registration has been made.`}
                  cancelText="Cancel"
                  confirmText="Resend"
                />
              )}
              {editParticpantModal && (
                <ConfirmationModal
                  cancel={() => toggleEditParticipantModal(false)}
                  confirm={() => {
                    toggleEditParticipantModal(false);
                    toggleEditParticipantDrawer(false);
                  }}
                  open={editParticpantModal}
                  title="Are you sure you want to cancel the action?"
                  cancelText="No, continue"
                  confirmText="Yes, cancel"
                />
              )}
            </>
          )}
        </div>
      </Paper>
      <AddOrderNoteDrawer
        open={addNoteDrawer}
        handleClose={() => toggleAddNoteDrawer(false)}
      />
    </>
  );
};

export default OrderDetails;
